
// 团队管理/销售团队
import { defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs } from "vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import 'dayjs/locale/zh-cn';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import { PlusOutlined } from "@ant-design/icons-vue";
import teamModal from "@/components/teamManage/teamModal.vue";
import teamDetails from '@/components/teamManage/teamDetails.vue';
import { queryData } from '@/api/teamManage';
export default defineComponent({
  name: "teamManage",
  components: {
    PlusOutlined,
    teamModal,
    teamDetails,
    breadcrumb
  },
  setup() {

    const rowKeys = ref<Array<string>>([])
    // 面包屑
    const breadData = ref<any []>([
        {title:'首页',path:'#/'},
        {title:'团队管理',path:'/index/team'},
        {title:'销售团队',path:'#/'},
    ])

    const queryForm = reactive({
      status: "",
      keywords: "",
    });
    const innerColumns = [
      {
        title: "名称",
        dataIndex: "team_name",
        key: "team_name",
        fixed: true,
        width:120 
      },
      {
        title: "级别",
        dataIndex: "role_name",
        key: "role_name",
      },
      {
        title: "联系人",
        dataIndex: "user_name",
        key: "user_name",
      },
      {
        title: "下级数",
        dataIndex: "son_num",
        key: "son_num",
        ellipsis: true,
        width:80
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        width: 100,
        ellipsis: true
      },
      {
        title: "加入/合约到期时间",
        dataIndex: "contract_time",
        key: "contract_time",
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 150,
        key: "address",
        ellipsis: true
      }
    ];
    const columns = [
      {
        title: "名称",
        dataIndex: "team_name",
        key: "team_name",
        fixed: true,
      },
      {
        title: "级别",
        dataIndex: "role_name",
        key: "role_name",
      },
      {
        title: "联系人",
        dataIndex: "user_name",
        key: "user_name",
        // width:300
        // ellipsis: true
      },
      {
        title: "下级数",
        dataIndex: "son_num",
        key: "son_num",
        ellipsis: true,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true
      },
      {
        title: "加入/合约到期时间",
        dataIndex: "join_time",
        key: "join_time",
        // ellipsis: true
        // width:280
      },
      {
        title: "操作",
        dataIndex: "operation",
        key: "address",
        ellipsis: true,
        width:150
      },
    ];
    const data = ref<Array<any>>([]);
    const teamModal:any = ref(null)
    const teamDetails = ref(null)
     // 市级表格
    const innerData = ref<Array<any>>([])

    onBeforeMount(()=>{
      query()
    })

 

    const handleChange = (val: any) => {
      // console.log(val);
    };

    const edit = (val:object,title:string)=>{
      teamModal.value.showModal(val,title,false)
    }
    const details = (val:object,title:string)=>{
      (teamDetails.value as any).open(val)
    }
    const create = ()=>{
      teamModal.value.showModal({},'团队申请',false)
    }

    const query = ():void =>{
      innerData.value = []
      queryData(queryForm).then((res:any)=>{
        if(res.code == 200){
          res.data.models.forEach((item:any,idx:number) => {
            res.data.models[idx].key = `${idx}`;
            // 有下级就展开
            if(res.data.models[idx].data){
              rowKeys.value.push(`${idx}`)
            }
            
            if(item.data && item.data.length > 0){
              item.data.forEach((data:any,index:number) => {
                item.data[index].key = `${index}`;
              });
            }
          });
          data.value = res.data.models;
        }
      })
    }

    // 创建编辑完成刷新数据
    const reload = ():void =>{
      query()
    }

    const rowClassName = (row:any,b:any)=>{
      
      if(row.role_id == 11){
        return 'station'
      }
      if(!row.data){
        return 'no-station'
      }
    }

    return {
      locale,
      ...toRefs(queryForm),
      handleChange,
      data,
      columns,
      innerData,
      innerColumns,
      teamModal,
      edit,
      details,
      create,
      teamDetails,
      query,
      reload,
      breadData,
      rowKeys,
      rowClassName,
      
    };
  },
});
