
import { defineComponent, onMounted, reactive, ref, toRefs , Ref,nextTick, onBeforeMount } from "vue";
import upload from '@/components/upload/upload.vue'
import dayjs,{ Dayjs } from 'dayjs';
import { editTeam , getCityList,getDetailsList,getUserRole} from '@/api/teamManage'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { getPickerTime,deepClone } from '@/utils/common'
import { message } from "ant-design-vue";
export default defineComponent({
    name:"teamModal",
    components:{
        upload
    },
    setup({},{emit}){
        // modal
        const visible = ref<boolean>(false);
        const loading = ref<boolean>(false);
        // upload
        const url:Ref<string> = ref('/agent-api/api/upload_file');
        const modalTitle:Ref<string> = ref('');
        // form
        const id = ref<any>()
        const sub_user_id = ref<any>()
        const join_time = ref<any>('')
        const contract_time = ref<any>('')
        const province_code = ref<string>('')
        const formRef = ref();
        const radioOptions = ref<Array<any>>([])
        const formState = reactive({
            role_id:'',
            commission_rate:'30',
            id_number:'',
            user_name:'',
            mobile:'',
            join_time:'',
            province_code:[],
            corporate_name:'',
            bank_card_number:'',
            bank_card_name:'',
            bank_card_subname:'',
            contract_time:'',
            id_card_image:'',
            manual_image:'',
            license_image:'',
            third_contract_image:'',
            last_contract_image:'',
            agreement_image:'',
            province:'',
            city:'',
            city_code:''
        });
        const isShowStation = ref(false)
        const isEdit = ref(false)

        let validateMobile = async ():Promise<any> =>{
            if(!formState.mobile){
                return Promise.reject('请输入联系电话')
            }else if(formState.mobile.length > 11 && formState.mobile.length < 1){
                return Promise.reject('请输入正确的联系电话')
            }else if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(formState.mobile)){
                return Promise.reject('请输入正确的联系电话')
            }else{
                return Promise.resolve();
            }
        }
        let validateID = async ():Promise<any> =>{
            if(!/^[0-9]+.?[0-9]*$/.test(formState.id_number)){
                return Promise.reject('请输入正确的身份证号')
            }else{
                return Promise.resolve();
            }
        }
        let validateBankNumber = async ():Promise<any> =>{
            if(!/^[0-9]+.?[0-9]*$/.test(formState.bank_card_number) || formState.bank_card_number.length < 10){
                return Promise.reject('请输入正确的开户行账号')
            }else{
                return Promise.resolve();
            }
        }
        let validateBankName = async ():Promise<any> =>{
            var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
	        regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
            if(regEn.test(formState.bank_card_name) || regCn.test(formState.bank_card_name)){
                return Promise.reject('请输入正确的开户行账号')
            }else{
                return Promise.resolve();
            }
        }
        let validateSubname = async ():Promise<any> =>{
            var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
	        regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
            if(regEn.test(formState.bank_card_name) || regCn.test(formState.bank_card_name)){
                return Promise.reject('请输入正确的开户行支行名称')
            }else{
                return Promise.resolve();
            }
        }
        const rules = {
            role_id:[{ required: true, message: '请选择代理商级别'}],
            commission_rate:[{ required: true, message: '请输入佣金比例', trigger: 'blur' }],
            user_name: [{ required: true, message: '请输入负责人', trigger: 'blur' },
            {min:1,max:10,message: '长度最少是一个字符，最多10个字符', trigger: 'blur',}],
            mobile: [{ required: true,validator: validateMobile, trigger: 'blur' },],
            id_number: [{ required: true, message: '请输身份证号', trigger: 'blur' },
            {min:18,max:18,message: '请输入正确的身份证号', trigger: 'blur',},
            {validator:validateID}],
            join_time: [{ required: true, message: '请选择加入时间' },],
            city: [{ required: true, message: '请输入所在城市'}],
            corporate_name: [{ required: true, message: '请输入公司名称', trigger: 'blur' },],
            bank_card_number: [{ required: true, message: '请输入开户行账号', trigger: 'blur'},
            {min:1,message: '请输入正确的开户行名称', trigger: 'blur',},
            {validator:validateBankNumber}],
            bank_card_name: [{ required: true, message: '请输入开户行名称', trigger: 'blur' },
                {validator:validateBankName}
            ],
            bank_card_subname: [{ required: true, message: '请输入开户行支行名称', trigger: 'blur'  },
                {validator:validateSubname}
            ],
            contract_time: [{ required: true, message: '请输入合同期限' },],
            province_code: [{ required: true, message: '请选择城市' },],
           
        };
        const upload1 = ref();
        const upload2 = ref();
        const upload3 = ref();
        const upload4 = ref();
        const upload5 = ref();
        const upload6 = ref();
        const showVal:Ref<boolean | undefined> = ref(false)
        const options = ref<Array<any>>([{
            label:'',
            value:'',
            children:[]
        }])
        onBeforeMount(()=>{
            getCityData()
            getRole()
        })

        // 打开弹框
        const showModal = (val:any,title:string,show?:boolean):void => {
            visible.value = true;
            modalTitle.value = title;
            showVal.value = show;

            id.value = val.id;
            sub_user_id.value = val.sub_user_id;
            // val==空 创建 否则为编辑
            if(val && (val as any).id){
                getDetailsList({id:(val as any).id}).then((res:any)=>{
                    if(res.code == 200){
                        for (const key in res.data) {
                            if(key == 'city_code'){
                                formState.province_code = [];
                                (formState.province_code as any).push(`${res.data.province_code}`);
                                (formState.province_code as any).push(`${res.data.city_code}`);
                                formState.city_code  = res.data.city_code;
                            }else if(key == 'join_time'){
                                (formState.join_time as any) = dayjs(res.data.join_time);
                            }else if(key == 'contract_time'){
                                (formState.contract_time as any) = dayjs(res.data.contract_time);
                            }else{
                                (formState as any)[key] = res.data[key];
                            }
                          
                        }
                        formState.province = res.data.province;
                        formState.city = res.data.city;
                        contract_time.value = res.data.contract_time;
                        join_time.value = res.data.join_time;
                        province_code.value = res.data.province_code;
                        isEdit.value = true;
                        
                    }
                })
            }else{
                isEdit.value = false;
            }
        };
        // 创建&编辑团队
        const handleOk = (e: MouseEvent) => {
            Promise.all([upload1.value.errMassage(),upload2.value.errMassage(),upload3.value.errMassage(),
            upload4.value.errMassage(),upload5.value.errMassage(),upload6.value.errMassage()]).then((res:any)=>{
                nextTick(()=>{
                    let data:any = {};
                    let url:string = '';
                    let userInfo:any = localStorage.getItem('userInfo')
                    data = deepClone(formState)
                    data.contract_time = contract_time.value;
                    data.join_time = join_time.value;
                    data.province_code = province_code.value;
                    
                    // return
                    if(modalTitle.value == '团队申请'){
                        url = '/agent-api/api/create_team';
                    }else{
                        url = '/agent-api/api/save_team';
                        
                        data.id = id.value;
                        data.city_code = formState.city_code?formState.city_code:'0';
                        data.sub_user_id = sub_user_id.value;
                    }
                    formRef.value.validate().then(() => {
                        editTeam(data,url).then((res:any)=>{
                            if(res.code == 200){
                                message.success(res.msg)
                                emit('list')
                                cancel()
                            }else{
                                message.error(res.msg)
                            }
                        })
                    }).catch((Error:any) => {
                        console.log('error', Error);
                    });
                })
                
            }).catch((err:string)=>{
                message.error(err)
            })
        };
        // 合同期限时间框
        const timeChange = (date: Dayjs)=>{
            contract_time.value = getPickerTime(date,1)
        }
        // 加入时间框
        const joinChange = (date:Dayjs):void =>{
           join_time.value = getPickerTime(date,1)
        }

        // 级联省市数据
        const getCityData = ():void =>{
            getCityList().then((res:any)=>{
                if(res.code == 200){
                    options.value[0].label = res.data.name;
                    options.value[0].value = res.data.code;
                    if(res.data.children.length){
                        res.data.children.forEach((ele:any,idx:number) => {
                            (options.value[0].children as Array<any>).push({
                                label:ele.name,
                                value:ele.code,
                            })
                        });
                    }
                }
            })
        }
        // 级联选择器change
        const casChange = (value:any,selectedOptions:any):void =>{
            formState.province = selectedOptions[0].label;
            province_code.value = selectedOptions[0].value;
            formState.city = selectedOptions[1]?selectedOptions[1].label:'';
            formState.city_code = selectedOptions[1]?selectedOptions[1].value:'';
        }
        // 取消弹框时 清空表单
        const cancel = ():void =>{
            visible.value = false;
            isShowStation.value = false;
            nextTick(()=>{
                formRef.value.resetFields();
                formState.mobile = '';
                formState.id_card_image = '';
                formState.manual_image = '';
                formState.license_image = '';
                (formState.third_contract_image as any) = '';
                formState.last_contract_image = '';
                formState.agreement_image = '';
            })
        }

        // 获取代理商级别
        const getRole = ()=>{
            getUserRole().then((res:any)=>{
                if(res.code == 200){
                    radioOptions.value = res.data;
                }
            })
        }

        const radioChange = (e:any)=>{
            formState.role_id = e.target.value;
            
            if(formState.role_id == '11' || formState.role_id == '9' || formState.role_id == '13'){
                isShowStation.value = true;
            }else{
                isShowStation.value = false;
            }
        }
        const numberChange = (val:any)=>{
            console.log(val);
            
            formState.commission_rate = val.toString();
        }
        
        const success = (val:any):void =>{
            formState.id_card_image = val[0]?val[0].path_file:'';
        }
        const success2 = (val:any):void =>{
            formState.manual_image = val[0]?val[0].path_file:'';
        }
        const success3 = (val:any):void =>{
            formState.license_image = val[0]?val[0].path_file:'';
        }
        const success4 = (val:any):void =>{
            formState.third_contract_image = val[0]?val[0].path_file:'';
        }
        const success5 = (val:any):void =>{
            formState.last_contract_image = val[0]?val[0].path_file:'';
        }
        const success6 = (val:any):void =>{
            formState.agreement_image = val[0]?val[0].path_file:'';
        }

        return{
            visible,
            formRef,
            formState,
            rules,
            modalTitle,
            loading,
            url,
            upload1,
            upload2,
            upload3,
            upload4,
            upload5,
            upload6,
            showVal,
            options,
            locale,
            success,
            showModal,
            handleOk,
            timeChange,
            casChange,
            joinChange,
            success2,
            success3,
            success4,
            success5,
            success6,
            cancel,
            radioOptions,
            radioChange,
            numberChange,
            isShowStation,
            isEdit
        }
    }
})
