
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { getDetailsList } from '@/api/teamManage'
export default defineComponent({
  name: "teamDetails",
  setup() {
    const visible = ref<boolean>(false);
    const data = ref<Array<any>>([])
    const open = (val:any): void => {
      visible.value = true;
      getDetailsList({id:val.id}).then((res:any)=>{
        if(res.code == 200){
          console.log(res.data);
          
          data.value = res.data;          
        }
      })
    };
    const close = (): void => {
      visible.value = false;
    };
    return {
      visible,
      data,
      open,
      close,
    };
  },
});
